
@media(min-width: 1150px) {
  .mantra-logo {
    height: 10vh;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .mantra-top {
    height: 50vh;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .mantra-player {
    height: 20vh;
  }

  .mantra-btm {
    height: 15vh;
  }

  .mantra-ftr {
    height: 5vh;
  }

  .log-left {
    text-align: center;
  }

  .icons-left {
    width: auto;
    height: 90vh;
  }

  .log-mid {
    text-align: center;
  }

  .logo {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 10vh;
  }

  .log-right {
    text-align: center;
  }

  .icons-right {
    width: auto;
    height: 90vh;
  }

  .top-mid {
    text-align: center;
  }

  .text-top {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 45vh;
  }

  .ply-mid {
    text-align: center;
    position: relative;
  }

  .player-buttons {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .link-left {
    position: absolute;
    left: 0px;
    width: 50%;
    height: 20vh;
  }

  .audio-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .link-right {
    position: absolute;
    right: 0px;
    width: 50%;
    height: 20vh;
  }

  .btm-mid {
    text-align: center;
    position: relative;
  }

  .text-btm {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text-ftr {
    width: auto;
    height: 5vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
